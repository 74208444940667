'use strict';

angular.module('kljDigitalLibraryApp.auth', [
        'kljDigitalLibraryApp.constants',
        'kljDigitalLibraryApp.util',
        'ngCookies',
        'ui.router'
    ])
    .config(["$httpProvider",
        function($httpProvider) {
            $httpProvider.interceptors.push('authInterceptor');
        }
    ]);